import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';
import FreeTrialSignup from '../../../components/FreeTrialSignup';

import './style.scss';

const EmailJournaling = () => {
    return (
        <Layout navTheme="light">
            <Seo
                title="Email journaling | GlassHive"
                description="With GlassHive you can easily see the span of a lead with email journaling. Learn more about the ways this feature can help your sales team.  "
            />
            <div className="email-journaling-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text">
                            <h1 className="white-text max-width-1100">
                                Save time with automated email journaling
                            </h1>
                            <p className="hero-subtext white-text">
                                Give your sales team a tool for added efficiency
                                and accuracy
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../images/features/lead-scrubbing/white-divider.svg')}
                    />
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="three-fifth video-column">
                            <WistiaPopoverVideo id={'t66l4ozvqd'}>
                                <img
                                    className="thumbnail"
                                    src={require('../../../images/features/email-journaling/thumbnail.png')}
                                    alt="Email journaling video thumbnail"
                                />
                            </WistiaPopoverVideo>
                        </Col>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">
                                Easily view prospect sales journey
                            </h4>
                            <p>
                                With GlassHive, your sales team doesn’t have to
                                manually enter each of their actions. View past
                                interactions with leads and set notifications
                                for future engagements. When an email is sent to
                                a prospect, GlassHive automatically records the
                                action and makes a record with our office 365
                                integration. No more sifting through your email
                                inbox for a message.
                            </p>
                            <p>
                                Save time and increase lead accuracy with the
                                email journal feature GlassHive offers. Check
                                out the video for more information.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="two-fifth">
                            <h4 className="centered-mobile">Save time</h4>
                            <p>
                                Eliminate manual entry of sales activities and
                                see everything in one contact view.
                            </p>
                            <h4 className="centered-mobile">
                                Accurate reporting
                            </h4>
                            <p>
                                See exactly where a prospect is located in the
                                sales funnel with automated recording.
                            </p>
                        </Col>
                        <Col className="three-fifth form-column">
                            <FreeTrialSignup titleText="Sign up for a free GlassHive account and start reaping the benefits" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default EmailJournaling;
